.add-visitor {
  .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: $xl-screen) {
      flex-direction: row;
    }
    .form-group {
      flex-grow: 1;
      @media (min-width: $xl-screen) {
        margin-right: $tiny-line-height;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}