.coming-soon {
  border: 0.1rem solid $light-periwinkle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  border-radius: 0.4rem;
  width: 80vw;
  .modal-header {
    padding: $small-font-size $base-font-size;
    background: $royal-purple;
    h2 {
      color: $white;
    }
  }
  .modal-body {
    padding: $base-font-size;
    p {
      font-size: $small-font-size;
      line-height: $small-line-height;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.sample-image {
  opacity: .33;
  img {
    margin-top: $head-line-height;
    max-width: 100%;
  }
}
