.visitors-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $tiny-line-height;
  margin-top: -0.1rem;
  border-top: 0.1rem solid $light-periwinkle;
  .page-link {
    @extend .btn;
    @extend .btn-text;
    > span[aria-hidden="true"] {
      position: relative;
      top: -0.13rem;
    }
    &:hover {
      background: transparent;
    }
  }
  .page-number {
    font-size: $small-font-size;
    line-height: $base-line-height;
    color: $greyish-purple;
  }
}