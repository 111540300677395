.visitor {
  border-bottom: 0.1rem solid $light-periwinkle;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $tiny-line-height;
  @media (min-width: $xl-screen) {
    flex-direction: row;
  }
  .visitor-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: calc($tiny-line-height / 2);
    @media (min-width: $xl-screen) {
      width: 40%;
      margin-bottom: 0;
    }
  }
  .visitor-info, .visit-datetime, .vizvid {
    display: flex;
    flex-direction: column;
    .visitor-name {
      font-weight: 700;
    }
    .visitor-email, label {
      font-size: $small-font-size;
      line-height: $small-line-height;
      color: $greyish-purple;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .visitor-info {
    margin: 0 $tiny-line-height 0 0;
    @media (min-width: $xl-screen) {
      max-width: 66%;
    }
  }
  .visit-datetime {
    margin-bottom: calc($tiny-line-height / 2);
    @media (min-width: $xl-screen) {
      margin-bottom: 0;
    }
    .timestamp {
      font-size: $small-font-size;
      line-height: $base-line-height;
    }
  }
  .visitor-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: $xl-screen) {
      flex-direction: row;
    }
    .btn {
      width: 100%;
      margin-bottom: calc($tiny-line-height / 2);
      @media (min-width: $xl-screen) {
        width: auto;
        margin-left: $tiny-line-height;
        margin-bottom: 0;
      }
    }
  }
}
.vizvid-editor {
  padding: $tiny-line-height;
  border-bottom: 0.1rem solid $light-periwinkle;
  margin-bottom: -0.1rem;
  @media (min-width: $xl-screen) {
    padding: $base-line-height;
  }
}
