label {
  display: block;
  font-size: $small-font-size;
  line-height: $small-line-height;
  font-weight: 500;
  letter-spacing: 0.033rem;
  color: $greyish-purple;
  margin: 0;
}
.form-control, .form-select {
  height: auto;
  font-size: $small-font-size;
  line-height: $base-line-height;
  color: $royal-purple;
  padding: calc($small-font-size / 2) $small-font-size;
  border: none;
  border-radius: 0.4rem;
  box-shadow: none;
}
select {
  &.form-select {
    height: $head-line-height;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
  }
}

.form-group {
  margin-bottom: 2.4rem;
}

.module-wrapper {
  .form-control, .form-select {
    background: $pale-grey;
    border-color: $pale-grey;
  }
  &:focus-within {
    .form-control {
      border-color: $royal-purple;
    }
  }
}

.submit-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
  @media (min-width: $xl-screen) {
    flex-direction: row;
  }
  .btn {
    &:first-child {
      order: 2;
      @media (min-width: $xl-screen) {
        order: 1;
      }
    }
    &:last-child {
      order: 1;
      @media (min-width: $xl-screen) {
        order: 2;
      }
    }
  }
}
