.navbar-custom {
  background-image: $navbar-gradient;
  padding: 0 $tiny-line-height;
  max-width: 100vw;

  .navbar-toggler {
    padding: 0;
    border: none;
    height: $head-line-height;
    width: $head-line-height;
    margin-right: -$tiny-line-height;
    order: 2;
    @media (min-width: $xl-screen) {
      margin-right: 0;
    }

    .navbar-toggler-icon {
      color: $white;
      height: $small-line-height;
      width: $small-line-height;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  .brand {
    height: $head-line-height;
    width: $head-line-height;
    padding: calc($tiny-line-height / 2);
    margin-left: -$tiny-line-height;
    order: 1;
    @media (min-width: $xl-screen) {
      margin-left: 0;
    }

    svg {
      path {
        fill: transparentize($white, 0.33);
      }
    }
  }

  .navbar-collapse {
    order: 3;
  }

  .nav-item {
    &:not(.dropdown) {
      .nav-link {
        font-size: $small-font-size;
        line-height: $head-line-height;
        font-weight: bold;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        padding: 0;
        color: $pale-grey;
        @media (min-width: $lg-screen) {
          line-height: $title-line-height*1.5;
          padding: 0 $tiny-line-height;
        }
      }
    }
  }

  .navbar-nav {
    .dropdown {
      .dropdown-toggle {
        padding: 0 !important;
        @media (min-width: $xl-screen) {
          padding: 0 1.6rem !important;
        }
      }
    }
  }
}

.navbar-padding {
  margin-top: $title-line-height;
  @media (min-width: $lg-screen) {
    margin-top: $title-line-height*2.5;
  }
}

.app-padding {
  padding: 0 $tiny-line-height;
  @media (min-width: $xl-screen) {
    padding: 0 $title-line-height*1.5;
  }
}
