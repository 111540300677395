.dropzones {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .dropzone-item {
    height: $title-line-height*3;
    width: $title-line-height*3;
    margin: 0 auto $base-line-height auto;
    position: relative;
    @media (min-width: $xl-screen) {
      margin: 0 $tiny-line-height $base-line-height 0;
    }
    .dropzone {
      height: 100%;
      background: $pale-grey;
      position: relative;
      input {
        width: 100%;
      }
      .thumbnail {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
      }
      .progress-bar-container {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .progress-bar {
          height: 100%;
          background: $bright-sky-blue;
          -webkit-transition: width 0.2s ease-out;
          -moz-transition: width 0.2s ease-out;
          -ms-transition: width 0.2s ease-out;
          -o-transition: width 0.2s ease-out;
          transition: width 0.2s ease-out;
        }
      }
      .icon-image {
        height: $title-line-height;
        width: $title-line-height;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
    }
    label {
      font-size: $small-font-size;
      line-height: $base-line-height;
      color: $greyish-purple;
      margin: 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }
}
