.search-container {
  display: flex;
  flex-direction: column;
  @media (min-width: $xl-screen) {
    flex-direction: row;
  }
  & > div {
    display: flex;
    flex-grow: 1;
    margin-bottom: $tiny-line-height;
    @media (min-width: $xl-screen) {
      margin-right: $base-line-height;
    }
    .search-component {
      .input-group-text {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        height: $head-line-height;
        width: $head-line-height;
        border: none;
        background: transparent;
      }
      .form-control {
        padding-left: $head-line-height - 0.4rem;
        border-radius: 0.4rem;
        position: relative;
        &:focus {
          z-index: 0;
        }
      }
      .input-group-append {
        position: absolute;
        right: 0;
        top: 0;
        .close {
          height: $head-line-height;
          width: $head-line-height;
          span {
            display: inline-block;
            background: $greyish-purple;
            line-height: $base-font-size;
            color: $white;
            height: $base-font-size;
            width: $base-font-size;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
