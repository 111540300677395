//Import theme variables before the component library
@import "variables";
@import "~bootstrap/scss/bootstrap.scss";

//Import global theme components
@import "buttons";
@import "forms";
@import "navbar";
@import "typography";
@import "comingsoon";

//Import individual components
@import "../../components/App/App";
@import "../../components/Analytics/Analytics";
@import "../../components/DateAndTime/DateAndTime";
@import "../../components/Icons/Icons";
@import "../../components/Image/Image";
@import "../../components/Login/Login";
@import "../../components/Logo/Logo";
@import "../../components/Visitor/Visitor";
@import "../../components/Visitors/Visitors";
@import "../../components/VisitorForm/VisitorForm";
@import "../../components/VisitorSearch/VisitorSearch";

body {
    background: #f6f6f6;
}

.alerts-container {
    z-index: 2000;
    position: fixed;
    top: 9.6rem;
    width: 100%;
}
.login .alerts-container {
    top: 0;
}
.alert {
    font-size: $small-font-size;
    border: none;
    border-radius: 0;
    margin: 0 !important;
    padding: 1.6rem !important;
    &.alert-info {
        background: lighten($vivid-purple, 48%);
        color: darken($vivid-purple, 22%);
    }
    &.alert-dismissible {
        .btn-close {
            font-size: $base-font-size;
            padding: 1.6rem;
            top: 50%;
            right: $tiny-line-height;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

.module-wrapper {
    background: $white;
    border-radius: 0.4rem;
    box-shadow: 0 0 4px 0 $light-periwinkle;
}
