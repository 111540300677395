.btn {
  font-size: $small-font-size;
  line-height: $head-line-height;
  font-weight: 700;
  letter-spacing: 0.07rem;
  color: $azure;
  background: $light-periwinkle;
  padding: 0 $base-line-height;
  border-radius: 0.4rem;
  border: none;
  box-shadow: none !important;
  max-height: $head-line-height;
  &[disabled] {
    opacity: 1;
    background: $light-periwinkle !important;
  }
  &:hover, &:focus {
    color: $white;
    background: $azure;
    box-shadow: none;
  }
  &:active {
    color: $white;
    background: $royal-purple;
  }
  &.btn-primary {
    color: $white;
    background: $vivid-purple;
    &:hover, &:focus {
      background: $royal-purple;
    }
  }
  &.btn-secondary {
    line-height: $head-line-height;
    color: $vivid-purple;
    padding: 0 $tiny-line-height;
    background: transparent;
    box-shadow: inset 0 0 0 0.2rem $light-periwinkle !important;
    &:hover, &:focus {
      color: $royal-purple;
      box-shadow: inset 0 0 0 0.4rem $royal-purple !important;
    }
  }
  &.btn-text {
    font-weight: normal;
    line-height: $base-line-height;
    color: $vivid-purple;
    padding: 0 $tiny-line-height;
    background: transparent;
    box-shadow: inset 0 0 0 0.2rem $light-periwinkle !important;
    &:hover, &:focus {
      color: $royal-purple;
      box-shadow: inset 0 0 0 0.2rem $royal-purple !important;
    }
  }
}