.dropdown-toggle {
  font-size: $small-font-size;
  line-height: $head-line-height;
  padding: 0 $tiny-line-height !important;
}
.dropdown-menu {
  right: 0;
  padding: 0;
  border-radius: 0 0 0.4rem 0.4rem;
  border: none;
  box-shadow: 0 0.2rem 0.4rem $light-periwinkle;
  .dropdown-item {
    font-size: $small-font-size;
    line-height: $head-line-height;
    padding: 0 $tiny-line-height;
  }
}
