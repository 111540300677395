html {
  //Reset to base-10 REMs
  font-size: 62.5%;
  body {
    //Setup defaults
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-family: $font-stack;
    font-weight: 400;
    -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
    text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
    -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
    font-feature-settings: 'liga'; /* Support for IE. */
  }
}

h1 {
  font-size: $head-font-size;
  line-height: $head-line-height;
  font-weight: 900;
  letter-spacing: normal;
  color: $royal-purple;
  margin: 0;
  @media (min-width: $xl-screen) {
    font-size: $title-font-size;
    line-height: $title-line-height;
  }
}

h2 {
  font-size: $subhead-font-size;
  line-height: $subhead-line-height;
  font-weight: 300;
  letter-spacing: -0.03rem;
  color: $royal-purple;
  margin: 0;
  @media (min-width: $xl-screen) {
    font-size: $head-font-size;
    line-height: $title-line-height;
  }
}

h3 {
  font-size: $base-font-size;
  line-height: $base-line-height;
  @media (min-width: $xl-screen) {
    font-size: $subhead-font-size;
    line-height: $subhead-line-height;
  }
}

p {
  font-size: $small-font-size;
  line-height: $small-line-height;
  color: $greyish-purple;
  margin-bottom: $small-line-height;
  @media (min-width: $xl-screen) {
    font-size: $base-font-size;
    line-height: $base-line-height;
    margin-bottom: $base-line-height;
  }
}