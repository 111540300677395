.date-time-input-group {
  display: flex;
  flex-direction: column;
  @media (min-width: $xl-screen) {
    flex-direction: row;
    margin-right: $tiny-line-height;
    width: 66%;
  }
  .form-control {
    &:first-child {
      margin: 0 $tiny-line-height 0 0;
    }
  }
  .form-group {
    @media (min-width: $xl-screen) {
      width: 50%;
    }
  }
}