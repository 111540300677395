//Colors
$royal-purple:      #2b005b;
$vivid-purple:      #7a00ff;
$azure:             #15A3FF;
$bright-sky-blue:   #00c4ff;
$sunflower-yellow:  #ffd700;
$poo-brown:         #8e5800;
$greyish-purple:    #827f85;
$light-periwinkle:  #cedbea;
$pale-grey:         #eef6ff;
$white:             #ffffff;

$navbar-gradient: linear-gradient(to right, $vivid-purple, $bright-sky-blue);

//Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,900');
$font-stack:            "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif;

$title-font-size:       5.2rem;
$title-line-height:     6.4rem;

$head-font-size:        3.2rem;
$head-line-height:      4.8rem;

$subhead-font-size:     2.4rem;
$subhead-line-height:   3.2rem;

$base-font-size:        2rem;
$base-line-height:      3.2rem;

$small-font-size:       1.6rem;
$small-line-height:     2.4rem;

$tiny-font-size:        1.3rem;
$tiny-line-height:      1.6rem;

//Spacer
$spacer: $base-line-height !default; //32px
$spacers: () !default;
$spacers: map-merge(
  (
          0: 0,
          1: ($spacer * .25),     //8px
          2: ($spacer * .5),      //16px
          3: ($spacer * .75),     //24px
          4: $spacer,             //32px
          5: ($spacer * 1.5),     //48px
          6: ($spacer * 2),       //64px
  ),
$spacers);

$xs-screen: 320px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;