.analytics-table {
    margin-bottom: 4rem;
    text-align: center;
    h2 {
        font-size: $head-font-size;
        line-height: $head-line-height;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #212529;
        @media (min-width: $xl-screen) {
            font-size: $title-font-size;
            line-height: $title-line-height;
        }
    }
    h3 {
        font-size: $subhead-font-size;
        line-height: $subhead-line-height;
        font-weight: 700;
        margin-bottom: 1rem;
        &.light {
            font-weight: 400;
        }
        @media (min-width: $xl-screen) {
            font-size: $head-font-size;
            line-height: $head-line-height;
        }
    }

    h4 {
        font-size: $base-font-size;
        line-height: $base-line-height;
        margin-bottom: 1rem;
        @media (min-width: $xl-screen) {
            font-size: $subhead-font-size;
            line-height: $subhead-line-height;
        }
    }
}

.recharts-legend-item-text {
    word-break: break-all;
}

.recharts-wrapper {
    font-size: $tiny-font-size;
    line-height: $tiny-line-height;
    @media (min-width: $md-screen) {
        font-size: $small-font-size;
        line-height: $small-line-height;
    }
}
