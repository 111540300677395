.logo-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $navbar-gradient;
    border-radius: 50%;
    padding: 1.6rem 1.4rem 1.2rem 1rem;
    height: $title-line-height;
    width: $title-line-height;

    svg {
        width: 100%;
        position: relative;

        path {
            fill: rgba(255, 255, 255, 0.9);
        }
    }
}
